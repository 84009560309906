import React from "react"
import Img from "gatsby-image"

import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../components/layout"
import BurgerMenu from "../components/burger-menu"

import style from "./index.module.css"
import AndvicHeader from "../components/andvic-header"
import SEO from "../components/seo"

const BackgroundSection = ({
  className,
  data,
}: {
  className: any
  data: any
}) => {
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <>
      <BackgroundImage
        Tag="section"
        className={className}
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
        <AndvicHeader></AndvicHeader>
      </BackgroundImage>
    </>
  )
}

export default function IndexPage(): JSX.Element {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "kitchen-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      home2: file(relativePath: { eq: "pool-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktop: file(relativePath: { eq: "pool-dusk.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1020) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Sydney Builders" />
      <BurgerMenu></BurgerMenu>
      <BackgroundSection
        className={style.header}
        data={data}
      ></BackgroundSection>
      {/* <div className={style.homeMain}>
        <div>
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          <h3>Duis aute irure</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </p>
        </div>
        <div className={style.home2}>
          <Img fluid={data.home2.childImageSharp.fluid} />
          <h3>Excepteur sint</h3>
          <p>
            In vel mi sit amet augue congue elementum. Morbi in ipsum sit amet
            pede facilisis laoreet. Donec lacus nunc, viverra nec, blandit vel,
            egestas et, augue.
          </p>
        </div>
      </div>
      <div className={style.testomonial}>
        "Aenean laoreet. Vestibulum nisi lectus, commodo ac, facilisis ac,
        ultricies eu, pede. Ut orci risus, accumsan porttitor, cursus quis,
        aliquet eget, justo."
        <br />
        <span>Jill Quotsmith</span>
      </div> */}
    </Layout>
  )
}
